











































import Vue, { PropType } from "vue";

import { SearchProfileDto } from "@/api/DoceoApi";

import DoceoImage from "@/components/DoceoImage.vue";
import DoceoIcon from "@/components/DoceoIcon.vue";
import DOCEO_ICONS from "@/constants/icons";

export default Vue.extend({
  name: "DoctorCard",
  components: { DoceoIcon, DoceoImage },
  props: {
    data: {
      type: Object as PropType<SearchProfileDto>,
    },
  },
  data: () => ({
    DOCEO_ICONS,
  }),
});
