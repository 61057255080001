










































































































































import Vue from "vue";
import { mapState, mapActions } from "pinia";

import { ProfileClient, SearchProfileDto, SearchProfileRequest } from "@/api/DoceoApi";

import DoctorCard from "@/components/contentItems/doctors/DoctorCard.vue";
import DoceoIcon from "@/components/DoceoIcon.vue";
import DOCEO_ICONS from "@/constants/icons";
import GeneralStatus from "@/constants/generalStatus";

export default Vue.extend({
  name: "DoctorSearch",
  metaInfo() {
    return {
      link: [{ rel: "canonical", href: `${process.env.VUE_APP_BASE_URL}${this.$route.path}` }],
    };
  },
  components: { DoctorCard, DoceoIcon },
  data: () => ({
    searchRequest: new SearchProfileRequest(),
    searchStatus: GeneralStatus.None,
    searchResults: [] as SearchProfileDto[],
    acceptingNewPatientOptions: [
      { text: "Yes", value: true },
      { text: "No", value: false },
    ],
    searchDebounceTimeout: undefined as number | undefined, // stores the timeout for search debouncing
    searchAlreadyRunningTimeout: undefined as number | undefined, // stores the timeout that reschedules searches when there is already a search running
    DOCEO_ICONS,
    GeneralStatus,
    filtersOpened: true,
  }),

  beforeRouteLeave(to, from, next) {
    // clear timeouts to prevent potential unnecessary requests
    window.clearTimeout(this.searchDebounceTimeout);
    window.clearTimeout(this.searchAlreadyRunningTimeout);
    next();
  },

  methods: {
    // makes the search function only run if the user hasn't pressed any keys in a short while
    runSearchDebounced() {
      window.clearTimeout(this.searchDebounceTimeout); // stop and clear any previously set timeout
      this.searchDebounceTimeout = window.setTimeout(this.runSearch, 250); // set a new timeout, delaying the search again
    },

    runSearch() {
      // if a search is already running, wait until it's done
      // since we are already debouncing it, this shouldn't be necessary unless the search is really slow
      if (this.searchStatus == GeneralStatus.Started) {
        window.clearTimeout(this.searchAlreadyRunningTimeout); // cancel any currently scheduled future search
        this.searchAlreadyRunningTimeout = window.setTimeout(this.runSearch, 250); // schedule a new search to run later
        return;
      }

      // if all fields are blank, treat it as if the user hasn't started a search yet
      if (
        (this.searchRequest.firstName == undefined || this.searchRequest.firstName.trim() == "") &&
        (this.searchRequest.lastName == undefined || this.searchRequest.lastName.trim() == "") &&
        (this.searchRequest.location == undefined || this.searchRequest.location.trim() == "") &&
        this.searchRequest.acceptingNewPatients == undefined &&
        (this.searchRequest.speciality == undefined || this.searchRequest.speciality == "") &&
        (this.searchRequest.subSpecialty == undefined || this.searchRequest.subSpecialty == "")
      ) {
        this.searchResults = [];
        this.searchStatus = GeneralStatus.None;
        return;
      }

      this.searchStatus = GeneralStatus.Started;

      this.searchResults = [];

      let profileClient = new ProfileClient();
      profileClient
        .searchProfiles(new SearchProfileRequest(this.searchRequest))
        .then((results) => {
          this.searchStatus = GeneralStatus.Finished;
          this.searchResults = results;
        })
        .catch((error) => {
          this.searchStatus = GeneralStatus.Error;
        });
    },

    showDoctor(userId: string | undefined) {
      if (userId) this.$router.push({ name: "Profile", params: { userId } });
    },
  },

  computed: {
    showFilters: function() {
      return this.$vuetify.breakpoint.mdAndUp || this.filtersOpened;
    },
  },
});
